import BannerCategoryItem from "./BannerCategoryItem";

const BannerCategories = ({ mainCategories }) => (
  <div className="hidden lg:block bg-purple-800">
    <ul className="container flex items-center space-x-3">
      {mainCategories.map((c) => (
        <BannerCategoryItem key={c.main.id} category={c} />
      ))}
    </ul>
  </div>
);

export default BannerCategories;
