import clsx from "clsx";
import dynamic from "next/dynamic";
import useFollowConsultant from "@/common/hook/useFollowConsultant";

const IconLike = dynamic(() => import("@/common/icons/like.svg"));
const IconLikeFill = dynamic(() => import("@/common/icons/like-fill.svg"));

const FavoriteButton = ({
  small = false,
  hideInMobile = false,
  className,
  backgroundColor = "bg-white",
  consultantId,
  initialValue,
  consultantSlug,
  consultantName,
  consultantImage,
}) => {
  const [isFollowing, follow, ref] = useFollowConsultant(
    consultantId,
    consultantSlug,
    consultantName,
    consultantImage,
    initialValue
  );
  const Icon = isFollowing ? IconLikeFill : IconLike;

  return (
    <button
      type="button"
      className={clsx(
        "hover:text-grey rounded-full focus:outline-none",
        backgroundColor,
        small ? "w-8 h-8" : "w-10 h-10",
        hideInMobile ? "hidden lg:flex-center" : "flex-center",
        isFollowing ? "text-grey" : "text-grey-600",
        className
      )}
      onClick={follow}
      ref={ref}
      aria-label="Follow Consultant"
    >
      <Icon className={small ? "w-4 h-4" : "w-6 h-6"} />
    </button>
  );
};

export default FavoriteButton;
