import useSWR from "swr";
import CacheKeys from "@/common/models/CacheKeys";
import request from "@/utils/request";
import { getCache } from "@/utils/cache";

const fetcher = (url) => {
  const randId = getCache(CacheKeys.RAND_ID);
  return request.get(url, { params: { randId } }).then((res) => res.data);
};

export default function useConsultantsByCategories(fallbackData) {
  const { data } = useSWR("/consultants/categories", fetcher, {
    fallbackData,
    revalidateOnMount: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return data;
}
