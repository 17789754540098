import Image from "next/image";
import useCache from "@/common/hook/useCache";
import BannerImageStore from "@/common/store/BannerImageStore";

const HeroBannerImage = () => {
  const [bannerImage] = useCache(BannerImageStore.KEY);

  return (
    <Image
      width={729}
      height={628}
      src={`/home/hero/background-${bannerImage || 1}.png`}
      alt="Person"
      className="!-mr-20 lg:!mr-0"
      quality={100}
    />
  );
};

export default HeroBannerImage;
