import Image from "next/image";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import { useMemo } from "react";
import IconArrowRight from "@/common/icons/arrow-right.svg";
import UserState from "@/common/models/UserState";
import Badge from "@/components/common/Badge";
import FavoriteButton from "@/components/common/FavoriteButton";
import CallConsultantButton from "@/components/common/CallConsultantPopup/CallConsultantButton";
import CrossLink from "@/components/common/CrossLink";
import ConsultantCardProfilePhoto from "@/components/home/CategorySliders/ConsultantCardProfilePhoto";
import friendlySeconds from "@/utils/friendlySeconds";

const ConsultantCard = ({ consultant, className, call }) => {
  const { t } = useTranslation("home");

  const qualifications = useMemo(
    () => consultant.qualifications.slice(0, 3).sort((a, b) => a.name.length - b.name.length),
    [consultant.qualifications]
  );

  return (
    <div
      className={clsx(
        "flex overflow-hidden flex-col h-[30rem] bg-white rounded-lg shadow-lg lg:hover:scale-105 select-none",
        className
      )}
    >
      <div className="flex relative justify-end items-start p-3 h-24 bg-purple-800">
        <Image src="/home/consultant-card-bg.jpg" layout="fill" objectFit="cover" quality={100} alt="Card Background" />
        <FavoriteButton
          initialValue={consultant.follow}
          consultantId={consultant.id}
          consultantName={consultant.displayName}
          consultantImage={consultant.picture}
          consultantSlug={consultant.slug}
          className="relative"
        />
      </div>

      <div className="flex flex-col flex-grow px-5 pb-6">
        <div className="flex -mt-16 font-semibold">
          <ConsultantCardProfilePhoto
            state={consultant.state}
            sound={consultant.sounds ? consultant.sounds[0] : null}
            picture={consultant.picture}
            slug={consultant.slug}
            name={consultant.displayName}
          />
          <div className={clsx("mt-18 ml-3 text-xs", UserState[consultant.state].textColor)}>
            {UserState.ONLINE.equals(consultant.state)
              ? t(UserState[consultant.state].languageKey)
              : t("common:format.last-seen", {
                  time: t("common:format.time-ago", {
                    time: friendlySeconds(
                      Math.round((Date.now() - new Date(consultant.stateChangedAt).getTime()) / 1000),
                      t
                    ),
                  }),
                })}
          </div>
        </div>

        <CrossLink className="block my-3" href={`/${consultant.slug}`}>
          <h3 className="text-xl font-semibold text-grey uppercase truncate">{consultant.displayName}</h3>
        </CrossLink>

        {/* consultant badges 52px = 24 (badge height) * 2 (line count) + 4 (gap) * 1 (line count - 1) */}
        <div className="flex overflow-y-hidden flex-wrap gap-x-2 gap-y-1 items-start mb-2 h-[52px]">
          {qualifications.map((q) => (
            <Badge color="grey" key={q.id} big={false}>
              {q.name}
            </Badge>
          ))}
        </div>

        {/* consultant description */}
        <p className="h-16 text-xs text-grey-700 line-clamp-4">{consultant.introduction}</p>

        {/* to the profile */}
        <div className="flex flex-col flex-grow justify-end items-center">
          <CrossLink href={`/${consultant.slug}`} className="flex-grow gap-2 p-2 button-text flex-center">
            <span>{t`consultant-slider.to-the-profile`}</span>
            <IconArrowRight />
          </CrossLink>
          <CallConsultantButton connects={consultant.connects} state={consultant.state} onClick={call} />
        </div>
      </div>
    </div>
  );
};

export default ConsultantCard;
