import clsx from "clsx";
import Image from "next/image";
import CrossLink from "@/components/common/CrossLink";

const TYPES_TO_SIZE = {
  small: "lg:w-[255px] lg:px-4",
  medium: "lg:w-[349px] lg:px-8",
  large: "lg:w-[442px] lg:px-16",
};

const AdvertisementCard = ({ advertisement, className, isMobile }) => {
  const hasPicture = !!advertisement.picture;

  return (
    <div
      className={clsx(
        "flex overflow-hidden relative flex-col items-center lg:py-6 px-4 pt-6 pb-4 w-full h-full bg-purple-800 rounded-lg shadow select-none",
        TYPES_TO_SIZE[advertisement.size],
        className,
        isMobile && "h-[476px]"
      )}
    >
      <Image
        src={hasPicture ? advertisement.picture : "/home/offer-card-bg.svg"}
        className="w-auto max-w-full max-h-28 lg:max-h-64"
        alt={advertisement.headline}
        layout="fill"
        objectFit="cover"
      />
      <div
        className={clsx(
          "flex relative flex-col flex-grow items-center",
          hasPicture ? "justify-end" : "gap-9 justify-center"
        )}
      >
        <h3 className={clsx("font-semibold text-center", hasPicture ? "text-xl" : "text-3xl")}>
          {advertisement.headline}
        </h3>
        <p className={clsx("text-center text-grey-800", hasPicture ? "mt-2.5 text-sm" : "text-xl")}>
          {advertisement.body}
        </p>
        <CrossLink
          href={advertisement.link}
          className={clsx("hover-orange", hasPicture ? "md:px-4 mt-5 button" : "px-0 w-full button-lg")}
        >
          {advertisement.buttonName}
        </CrossLink>
        {advertisement.disclaimer && <small className="text-center text-grey-800">{advertisement.disclaimer}</small>}
      </div>
    </div>
  );
};

export default AdvertisementCard;
