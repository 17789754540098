import { useCallback } from "react";
import CategorySlider from "./CategorySlider";
import useConsultantsByCategories from "@/common/hook/useConsultantsByCategories";

const CategorySliders = ({ initialConsultants, advertisement, activeCategory, blogPosts, featuredCategories }) => {
  const consultants = useConsultantsByCategories(initialConsultants);

  const findAdvertisementsByCategory = useCallback(
    (categoryId) =>
      advertisement.filter(({ category }) =>
        Array.isArray(category) ? category.includes(categoryId) : category === categoryId
      ),
    [advertisement]
  );

  return (
    <>
      {activeCategory && (
        <CategorySlider
          category={activeCategory.main}
          blogPosts={blogPosts[activeCategory.main.id]}
          consultants={consultants[activeCategory.main.id]}
          findAdvertisementsByCategory={findAdvertisementsByCategory}
        />
      )}
      {featuredCategories.map(({ main }) => (
        <CategorySlider
          key={main.id}
          category={main}
          findAdvertisementsByCategory={findAdvertisementsByCategory}
          blogPosts={blogPosts[main.id]}
          consultants={consultants[main.id]}
        />
      ))}
    </>
  );
};

export default CategorySliders;
