import Link from "next/link";
import { Fragment, useMemo } from "react";

const BannerCategoryItem = ({ category }) => {
  const { subCategories, subMethodCategories } = useMemo(
    () =>
      category.sub?.reduce(
        (stack, curr) => {
          stack[curr.method ? "subMethodCategories" : "subCategories"].push(curr);
          return stack;
        },
        { subCategories: [], subMethodCategories: [] }
      ),
    [category.sub]
  );

  return (
    <li className="group relative select-none">
      <Link href={{ pathname: "/search", query: { category: category.main.slug } }}>
        <a className="block py-2 px-5 md:px-6 font-semibold text-grey-900 rounded-lg border border-grey-400 hover:border-purple">
          {category.main.name}
        </a>
      </Link>
      {category.sub.length > 0 && (
        <div tabIndex={-1} className="hidden group-hover:block absolute top-full z-30">
          <div className="pt-2">
            <div className="flex overflow-hidden bg-white rounded-lg divide-x divide-grey-300 shadow">
              <div className="flex flex-col gap-1.5 w-64">
                <div className="py-4 px-6 font-semibold">Kategorien</div>
                {subCategories.map((sub) => (
                  <Fragment key={sub.id}>
                    <Link href={{ pathname: "/search", query: { category: sub.slug } }}>
                      <a className="py-2 px-6 w-full hover:bg-grey-100">{sub.name}</a>
                    </Link>
                  </Fragment>
                ))}
              </div>
              {subMethodCategories.length > 0 && (
                <div className="flex flex-col gap-1.5 w-64">
                  <div className="py-4 px-6 font-semibold">Methoden</div>
                  {subMethodCategories.map((sub) => (
                    <Fragment key={sub.id}>
                      <Link href={{ pathname: "/search", query: { category: sub.slug } }}>
                        <a className="py-2 px-6 w-full hover:bg-grey-100">{sub.name}</a>
                      </Link>
                    </Fragment>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default BannerCategoryItem;
