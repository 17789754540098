import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import { useMemo } from "react";
import UserState from "@/common/models/UserState";

// TODO : I18N
const CallConsultantButton = ({ state, onClick, connects, grow = true }) => {
  const { t } = useTranslation("common");

  const [buttonClassName, buttonText] = useMemo(() => {
    if (connects?.length > 0) {
      if (UserState.ONLINE.equals(state)) {
        return [
          "bg-green hover:bg-green-900",
          connects?.length === 1 ? t(`connections.${connects[0].type}`) : "Jetzt erreichbar",
        ];
      }
      return ["bg-grey-600 hover:bg-grey-500", t`call-consultant-popup.request-callback`];
    }

    return ["bg-grey-600 hover:bg-grey-500 cursor-not-allowed", "Zurzeit nicht erreichbar"];
  }, [connects, state, t]);

  return (
    <button
      type="button"
      className={clsx("button", grow && "w-full lg:w-11/12", buttonClassName)}
      onClick={connects?.length > 0 ? onClick : undefined}
    >
      <span className="font-semibold">{buttonText}</span>
      {connects?.length > 0 && (
        <span className="ml-1 text-xs font-medium text-white">
          {connects?.length > 1 && "ab "}
          {t("format.tariff", { tariff: connects[0].tariff / 100 })}
        </span>
      )}
    </button>
  );
};

export default CallConsultantButton;
