import Link from "next/link";
import SearchBar from "@/components/common/SearchBar";
import IconFilter from "@/common/icons/filter.svg";

const SearchSection = () => (
  <div className="flex mt-6 md:mt-12 h-12 md:h-18">
    <SearchBar />
    <Link href="/search?filter=on">
      <a className="flex-shrink-0 ml-4 md:ml-6 w-12 md:w-18 h-12 md:h-18 shadow-md button-icon" aria-label="Filter">
        <IconFilter className="w-6 md:w-9 h-6 md:h-9" />
      </a>
    </Link>
  </div>
);

export default SearchSection;
