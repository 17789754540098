import Link from "next/link";
import Image from "next/image";
import clsx from "clsx";
import useTranslation from "next-translate/useTranslation";
import IconArrow from "@/common/icons/arrow-right.svg";
import CrossLink from "@/components/common/CrossLink";

const TYPES = { BLOG: "blog", EVENT: "event" };

const BlogCard = ({ blog, type = TYPES.BLOG }) => {
  const { t } = useTranslation("common");

  return (
    <div
      className={clsx(
        "grid overflow-hidden grid-rows-1-auto w-64 h-full bg-white rounded-lg shadow-lg select-none",
        type === TYPES.BLOG ? "lg:w-80 2xl:w-96" : "lg:w-[22rem]"
      )}
    >
      <Link href={`/blog/${blog.slug}`} passHref>
        <div className="flex relative flex-col flex-grow p-5 pb-3 bg-purple-800 cursor-pointer">
          <Image alt="Blog Background" src="/home/blog-bg.jpg" layout="fill" quality={100} objectFit="cover" />
          <div className="overflow-hidden relative rounded-t-lg rounded-b-none">
            <div className="relative w-full">
              <Image
                src={`${process.env.NEXT_PUBLIC_STORAGE_URL}/blog/${blog.picture}`}
                alt="Blog Banner"
                width={640}
                height={400}
              />
            </div>
            <div
              className={clsx(
                "absolute top-3 right-3 py-1 px-3 text-sm text-white capitalize rounded-lg",
                type === TYPES.BLOG ? "bg-pink" : "bg-blue"
              )}
            >
              {t(`content-types.${type}`)}
            </div>
          </div>
          <div className="relative">
            <strong className="block mt-3 text-base font-semibold">{blog.title}</strong>
            <span className="block mt-1 text-xs text-grey-700">
              {t("format.from", { from: blog.consultant?.displayName || "Vialantis" })}
            </span>
          </div>
        </div>
      </Link>
      <div className="flex flex-col py-6 px-5 space-y-3">
        <p className="text-sm text-grey-700 line-clamp-3">{blog.description}</p>
        <CrossLink
          href={`/blog/${blog.slug}`}
          className="gap-2 self-end text-green hover:text-green-900 button-text flex-center"
        >
          <span>{t`read-more`}</span>
          <IconArrow />
        </CrossLink>
      </div>
    </div>
  );
};

export default BlogCard;
