import { useEffect, useState } from "react";
import Trans from "next-translate/Trans";

const TOPICS = ["Therapeuten", "Coaches", "Kartenleger", "Psychologen", "Hellseher", "Astrologen", "Traumdeuter"];

const IntervalTopicText = () => {
  const [topic, setTopic] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => setTopic((prev) => (prev >= TOPICS.length - 1 ? 0 : prev + 1)), 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Trans
      i18nKey="home:banner.subtitle.2"
      components={{
        highlight: <span className="text-orange" />,
      }}
      values={{ topic: `#${TOPICS[topic]}` }}
    />
  );
};

export default IntervalTopicText;
