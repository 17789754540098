import { useCallback } from "react";
import Router from "next/router";
import { mutate } from "swr";
import useUser from "@/common/hook/user/useUser";
import request from "@/utils/request";
import { setCache } from "@/utils/cache";
import CacheKeys from "@/common/models/CacheKeys";

export default function usePingChat() {
  const { isAuthenticated } = useUser();

  return useCallback(
    (consultant) => {
      if (!isAuthenticated) {
        Router.push({
          pathname: "/register",
          query: {
            consultantName: consultant.displayName,
            consultantImage: consultant.picture,
            redirectTo: `/${consultant.slug}`,
          },
        });
        return;
      }

      setCache(CacheKeys.CHAT_RECEIVER_ID, consultant.id);
      setCache(CacheKeys.CHAT_ACTIVE, true);

      request.get(`/chat-ping/${consultant.id}`).then(
        () => {
          mutate(`/chat-check/${consultant.id}`);
        },
        () => setCache(CacheKeys.CHAT_ACTIVE, false)
      );
    },
    [isAuthenticated]
  );
}
