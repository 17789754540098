
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import useTranslation from "next-translate/useTranslation";
import Layout from "@/components/core/Layout";
import HeroBanner from "@/components/home/HeroBanner";
import request from "@/utils/request";
import BannerCategories from "@/components/home/BannerCategories";
import SearchSection from "@/components/home/SearchSection";
import CategorySlider from "@/components/home/CategorySlider";
import ConsultantSliders from "@/components/home/CategorySliders";

const HomePage = ({
  activeCategory,
  featuredCategories,
  mainCategories,
  advertisement,
  blogPosts,
  consultants: initialConsultants,
}) => {
  const { t } = useTranslation("home");

  return (
    <Layout hasFooterCard showSearch>
      <BannerCategories mainCategories={mainCategories} />
      <HeroBanner />
      <div className="container py-16 lg:py-32">
        <h2 className="text-xl md:text-4xl font-semibold text-center">{t`search.title`}</h2>
        <p className="hidden md:block mt-3 text-center text-grey-700">{t`search.subtitle`}</p>
        <SearchSection />
        <CategorySlider mainCategories={mainCategories} />
        <ConsultantSliders
          initialConsultants={initialConsultants}
          blogPosts={blogPosts}
          advertisement={advertisement}
          activeCategory={activeCategory}
          featuredCategories={featuredCategories}
        />
        {/* <UserComments /> */}
      </div>
    </Layout>
  );
};

 async function _getStaticProps() {
  const [{ data: consultants }, { data: categories }, { data: advertisement }] = await Promise.all([
    request.get("/consultants/categories"),
    request.get("/categories"),
    request.get("/advertisement"),
  ]);

  let activeCategory = null;
  const featuredCategories = [];
  const mainCategories = [];
  const blogPosts = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const category of categories) {
    const { featured: isFeatured, main: isMain } = category.main;
    if (isFeatured) {
      if (activeCategory === null && isMain) {
        activeCategory = category;
      } else {
        featuredCategories.push(category);
      }

      // eslint-disable-next-line no-await-in-loop
      const { data: blogPostsByCategory } = await request.get("/blog-posts/search", {
        params: { category: category.main.slug },
      });
      blogPosts[category.main.id] = Array.isArray(blogPostsByCategory) ? blogPostsByCategory : [];
    }

    if (isMain) {
      mainCategories.push(category);
    }
  }

  return {
    props: {
      activeCategory,
      featuredCategories,
      blogPosts,
      mainCategories,
      advertisement,
      consultants,
    },
    revalidate: 60,
  };
}

export default HomePage;


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  