import { forwardRef } from "react";
import Link from "next/link";

const HighlightLink = ({ children, categoryId }, ref) => (
  <Link href={`/search?category=${categoryId}`}>
    <a className="block lg:inline p-0 text-xl lg:text-4xl text-orange hover:text-orange-900 button-text" ref={ref}>
      {children}
    </a>
  </Link>
);

export default forwardRef(HighlightLink);
