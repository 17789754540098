import { SwiperSlide } from "swiper/react";
import Trans from "next-translate/Trans";
import { useEffect, useMemo, useState } from "react";
import dynamic from "next/dynamic";
import ConsultantCard from "./ConsultantCard";
import Slider from "@/components/common/Slider";
import AdvertisementCard from "./AdvertisementCard";
import shuffle from "@/utils/shuffle";
import useIsMobile from "@/common/hook/useIsMobile";
import HighlightLink from "./HighlightLink";
import BlogCard from "@/components/common/BlogCard";
import useCallConsultant from "@/common/hook/useCallConsultant";

const CallConsultantPopup = dynamic(() => import("@/components/common/CallConsultantPopup"));

const CategorySlider = ({ category, findAdvertisementsByCategory, blogPosts, consultants }) => {
  const isMobile = useIsMobile();
  const advertisements = useMemo(
    () => findAdvertisementsByCategory(category.id),
    [findAdvertisementsByCategory, category.id]
  );
  const [blogsAndAdvertisements, setBlogsAndAdvertisements] = useState([]);
  const [callConsultant, setCallConsultant] = useState(null);
  const {
    call,
    activeComponent: ActiveComponent,
    setActivePopup,
    callType,
  } = useCallConsultant(() => setActivePopup(null));

  useEffect(() => {
    setBlogsAndAdvertisements(isMobile ? blogPosts : shuffle([...(blogPosts ?? []), ...advertisements]));
  }, [blogPosts, advertisements, isMobile]);

  return (
    <>
      <Slider
        className="mt-20 lg:mt-32"
        slidesPerView={1.2}
        breakpoints={{
          768: { slidesPerView: 2.5 },
          1024: { slidesPerView: 3.5 },
          1280: { slidesPerView: 4 },
          1536: { slidesPerView: 4.5 },
        }}
        text={
          <>
            <h2 className="text-xl lg:text-4xl font-semibold text-center lg:text-left">
              <Trans
                i18nKey="home:consultant-slider.title"
                components={{ highlight: <HighlightLink categoryId={category.slug} /> }}
                values={{ category: category.name }}
              />
            </h2>
            <p className="hidden md:block mt-3 text-grey-700">{category.subtitle}</p>
          </>
        }
      >
        {consultants?.map((consultant) => (
          <SwiperSlide key={consultant.id} className="py-4 lg:py-8">
            <ConsultantCard
              consultant={consultant}
              call={() => {
                setCallConsultant(consultant);
                if (consultant.connects.length === 1) {
                  call(consultant, consultant.connects[0]);
                }
              }}
            />
          </SwiperSlide>
        ))}
      </Slider>
      {ActiveComponent && (
        <ActiveComponent consultant={callConsultant} onClose={() => setActivePopup(null)} callType={callType} />
      )}
      {callConsultant !== null && callConsultant.connects.length > 1 && (
        <CallConsultantPopup consultant={callConsultant} onClose={() => setCallConsultant(null)} />
      )}
      {blogsAndAdvertisements.length > 0 && (
        <Slider>
          {blogsAndAdvertisements.map((object) => (
            <SwiperSlide key={object.id} className="py-4 lg:py-8 !h-auto">
              {object.size ? <AdvertisementCard advertisement={object} /> : <BlogCard blog={object} />}
            </SwiperSlide>
          ))}
        </Slider>
      )}
      {isMobile && advertisements.length > 0 && (
        <div className="flex flex-col space-y-4 w-full">
          {advertisements.map((advertisement) => (
            <AdvertisementCard isMobile key={advertisement.id} advertisement={advertisement} />
          ))}
        </div>
      )}
    </>
  );
};

export default CategorySlider;
