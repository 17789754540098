import Image from "next/image";
import useOutsideClick from "@/common/hook/useOutsideClick";

const MobileSolutionItem = ({ image, alt, title, subtitle, setTooltip }) => {
  const ref = useOutsideClick(() => setTooltip(false));

  return (
    <div
      className="flex relative flex-col items-center md:w-1/2"
      onClick={() => setTooltip(subtitle)}
      ref={ref}
      role="presentation"
    >
      <div className="flex-shrink-0">
        <Image src={image} width={48} height={48} alt={alt} />
      </div>
      <h2 className="font-sans text-sm font-semibold">{title}</h2>
    </div>
  );
};

export default MobileSolutionItem;
