import { createElement } from "react";
import clsx from "clsx";
import Image from "next/image";
import dynamic from "next/dynamic";
import IconState from "@/common/icons/online-state.svg";
import UserState from "@/common/models/UserState";
import CrossLink from "@/components/common/CrossLink";

const ProfileVoiceRecord = dynamic(() => import("@/components/common/ProfileVoiceRecord"));

const ConsultantCardProfilePhoto = ({ slug, picture, state, name, sound }) =>
  createElement(
    sound ? "div" : CrossLink,
    {
      href: sound ? undefined : `/${slug}`,
      className: clsx(
        "group relative flex-shrink-0 w-32 h-32 rounded-full border-4",
        sound ? "border-purple animate-border-color" : "border-purple-700"
      ),
    },
    <>
      <Image
        src={`${process.env.NEXT_PUBLIC_STORAGE_URL}/consultants/${picture}`}
        layout="fill"
        alt={name}
        className="rounded-full"
        quality={100}
      />
      <IconState className={clsx("absolute bottom-0 left-1 z-10 w-12", UserState[state].textColor)} />
      {sound && <ProfileVoiceRecord sound={sound} size={(32 / 4) * 16} negative />}
    </>
  );

export default ConsultantCardProfilePhoto;
