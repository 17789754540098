import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import IntervalTopicText from "./IntervalTopicText";
import MobileSolutions from "./MobileSolutions";
import HeroBannerImage from "@/components/home/HeroBanner/HeroBannerImage";

const HeroBanner = () => {
  const { t } = useTranslation("home");

  return (
    <>
      <div className="overflow-hidden relative h-72 md:h-[500px] lg:h-auto bg-purple-800">
        <div className="container flex justify-between">
          <div className="z-20 self-center mt-12 md:mt-20 lg:mb-14 xl:mb-10 space-y-3 md:space-y-6 lg:space-y-12 w-[13rem] md:w-80 lg:w-[26rem] xl:w-[34rem]">
            <h1 className="font-title text-2xl md:text-3xl lg:text-6xl font-bold">{t`banner.title`}</h1>
            <div className="font-sans text-xs md:text-lg lg:text-xl leading-5 lg:leading-[2.25rem] text-grey-800">
              <p>{t`banner.subtitle.1`}</p>
              <p>
                <IntervalTopicText />
              </p>
              <p>{t`banner.subtitle.3`}</p>
            </div>
            <Link href="/register">
              <a className="inline-block button-lg hover-orange">{t`banner.try-us`}</a>
            </Link>
          </div>
          <div className="lg:flex absolute lg:relative right-0 bottom-0 max-h-full align-bottom">
            <div className="hidden lg:flex absolute lg:top-40 xl:top-36 -left-14 z-10 items-center p-3 w-72 bg-white rounded-lg">
              <div className="absolute -top-7 -right-7 z-10">
                <Image src="/home/hero/chat-2.svg" alt="Message Icon" width={48} height={48} />
              </div>

              <div className="flex flex-shrink-0 mr-3">
                <Image src="/home/hero/chat.svg" width={60} height={80} alt="Note Icon" />
              </div>
              <div className="flex flex-col space-y-1">
                <h2 className="font-sans text-base font-semibold">{t`banner.chat-advice.title`}</h2>
                <p className="font-sans text-xs text-grey-700">{t`banner.chat-advice.subtitle`}</p>
              </div>
            </div>
            <div className="hidden lg:flex absolute bottom-24 lg:-left-10 xl:-left-16 z-10 items-center pt-3 pr-6 pb-3 pl-3 w-[22rem] bg-white rounded-lg">
              <div className="flex flex-shrink-0 mr-3">
                <Image src="/home/hero/call.svg" width={48} height={48} alt="Phone Icon" />
              </div>
              <div>
                <h2 className="font-sans text-base font-semibold">{t`banner.telephone.title`}</h2>
                <p className="mt-1 font-sans text-xs leading-5 text-grey-700">{t`banner.telephone.subtitle`}</p>
              </div>
            </div>
            <div className="hidden lg:flex absolute top-44 right-0 z-10 flex-col p-6 w-44 bg-white rounded-lg">
              <div className="flex flex-shrink-0">
                <Image src="/home/hero/video.svg" width={149} height={80} alt="Video Icon" />
              </div>
              <div className="mt-5">
                <h2 className="font-sans text-base font-semibold text-center">{t`banner.video-advice.title`}</h2>
                <p className="mt-1 font-sans text-xs leading-5 text-center text-grey-700">
                  {t`banner.video-advice.subtitle`}
                </p>
              </div>
            </div>
            <HeroBannerImage />
          </div>
        </div>
      </div>
      <MobileSolutions />
    </>
  );
};

export default HeroBanner;
