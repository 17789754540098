import { useCallback, useEffect, useState } from "react";
import Router from "next/router";
import request from "@/utils/request";
import useOnceInView from "@/common/hook/useOnceInView";

export default function useFollowConsultant(
  consultantId,
  consultantSlug,
  consultantName,
  consultantImage,
  initialValue
) {
  const [isViewed, elementRef] = useOnceInView();
  const [isFollowing, setFollowing] = useState(initialValue || false);

  useEffect(() => {
    // FOR LAZY LOADING STATES
    if (typeof initialValue === "boolean") {
      setFollowing(initialValue);
    }
  }, [initialValue]);

  const handleResponse = useCallback(({ data }) => setFollowing(data.follow), []);

  useEffect(() => {
    if (typeof initialValue !== "boolean" && isViewed) {
      request.get("/follow", { params: { consultantId } }).then(handleResponse);
    }
  }, [consultantId, handleResponse, initialValue, isViewed]);

  const follow = useCallback(() => {
    // CHECK USER IS AUTHENTICATED
    if (request.defaults.headers.common.Authorization) {
      request.get("/follow/consultant", { params: { consultantId } }).then(handleResponse);
    } else {
      Router.push({
        pathname: "/login",
        query: {
          consultantName,
          consultantImage,
          type: "follow",
          redirectTo: `/${consultantSlug}`,
        },
      });
    }
  }, [consultantId, consultantImage, consultantName, consultantSlug, handleResponse]);

  return [isFollowing, follow, elementRef];
}
